.react-calendar {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border: none;
  width: 100%;
  margin-top: 6px;
  font-family: Averta, 'sans-serif';
  padding: 20px 10px 20px 10px;
}

.react-calendar__tile {
  border-radius: 4px;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3509FA;
}

.react-calendar__navigation {
  margin-bottom: 12px;
}

.react-calendar__month-view__weekdays__weekday > abbr[title] {
  text-decoration: none;
  text-transform: capitalize;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent;
  color: #B0B0B0
}

.react-calendar__month-view__days__day:disabled {
  background-color: transparent;
}

.react-calendar__month-view__days__day:disabled > abbr {
  color: #B0B0B0;
  text-decoration: line-through;
}