@font-face {
  font-family: Averta;
  src: url('../fonts/averta/Averta-Regular.otf');
}

@font-face {
  font-family: Averta;
  font-weight: 500;
  src: url('../fonts/averta/Averta-Bold.otf');
}

@font-face {
  font-family: Averta;
  font-weight: 700;
  src: url('../fonts/averta/Averta-Semibold.otf');
}

@font-face {
  font-family: Averta;
  font-weight: 800;
  src: url('../fonts/averta/Averta-ExtraBold.otf');
}

body {
  background-color: #F6F7F8;
}
